import React from "react";

import { graphql } from "gatsby";

import { 
    getChallengeStatus,
    Layout,
    FirstLanding
} from "../components";

const FirstLandingPage = ({ data }) => {

    const challenges = data.allWpChallenge.nodes;
    const formattedChallenges = formatChallenges(challenges);

    const title = "Landing";

    return (
        <Layout 
            fullWidth={true}
            hideMenu={true}
            title={title}
        >
            <FirstLanding challenges={formattedChallenges} />
        </Layout>
    )
}

export default FirstLandingPage;

const formatChallenges = (challenges) => {
    
    let formattedChallenges = challenges.map((challenge) => {
        let status = getChallengeStatus(challenge);
        return { ...challenge, status: status };
    });

    let groupedChallenges = groupBy(formattedChallenges, challenge => challenge.status);

    let running = groupedChallenges.get('running');
    let waiting = groupedChallenges.get('waiting');
    let over = groupedChallenges.get('over');

    let _challenges = [];
    if ( running !== undefined ) {
        _challenges = _challenges.concat(running);
    }

    return _challenges;
}

const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const pageQuery = graphql`
    query ChallengeResults {
        allWpChallenge(
            sort: { fields: [date], order: DESC }
            limit: 100
            skip: 0
        ) {
            nodes {
                id
                databaseId
                title
                slug
                startDatetime
                endDatetime
                questionVideo
                rewardImage {
                    altText
                    localFile {
                        icon: childImageSharp {
                            gatsbyImageData(height: 32)
                        }
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 200)
                        }
                    }
                }
                rewardName
                rewardSpots
            }
        }
    }
`